export default [
  {
    path: '/settings/auto-partner',
    name: 'auto-partner-setting',
    component: () => import('@/views/settings/AutoPartnerSetting.vue'),
    meta: {
      pageTitle: 'Auto Partner Setting',
      action: 'read',
      resource: 'AutoPartner',
    },
  },

  {
    path: '/settings/partners-keys',
    name: 'partners-keys-setting',
    component: () => import('@/views/settings/PartnersKeys.vue'),
    meta: {
      pageTitle: 'PartnersKeys Setting',
      action: 'read',
      resource: 'PartnersKeys',
    },
  },
  {
    path: '/settings/partners-keys/edit/:id',
    name: 'partners-keys-setting-edit',
    component: () => import('@/views/settings/PartnersKeysEdit.vue'),
    meta: {
      pageTitle: 'PartnersKeys Setting Edit',
      action: 'read',
      resource: 'PartnersKeys',
      navActiveLink: 'partners-keys-setting',
      breadcrumb: [
        {
          text: 'PartnersKeys Setting',
          to: { name: "partners-keys-setting" },
        },
        {
          text: 'PartnersKeys Setting Edit',
          to: { name: "partners-keys-setting-edit" },
        },
      ],
    },
  },
  {
    path: '/settings/partners-keys/create',
    name: 'partners-keys-setting-create',
    component: () => import('@/views/settings/PartnersKeysCreate.vue'),
    meta: {
      pageTitle: 'PartnersKeys Setting Create',
      action: 'read',
      resource: 'PartnersKeys',
      navActiveLink: 'partners-keys-setting',
      breadcrumb: [
        {
          text: 'PartnersKeys Setting',
          to: { name: "partners-keys-setting" },
        },
        {
          text: 'PartnersKeys Setting Create',
          to: { name: "partners-keys-setting-create" },
        },
      ],
    },
  },
]
