import Vue from 'vue'
import VueRouter from 'vue-router'
import { canNavigate } from '@/libs/acl/routeProtection'
// eslint-disable-next-line import/no-cycle
import { isUserLoggedIn, getUserData } from '@/auth/utils'
import accounts from '@/router/routes/accounts'
import apps from '@/router/routes/apps'
import reports from '@/router/routes/reports'
import billing from '@/router/routes/billing.js'
import logs from '@/router/routes/logs.js'
import settings from '@/router/routes/settings'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...apps,
    ...reports,
    ...accounts,
    ...billing,
    ...logs,
    ...settings,
  ],
})

// Router Before Each hook for route protection
router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()
  const user = getUserData()
  const isRequireChangePassword = user?.requireChangePassword ?? false

  if (isRequireChangePassword && to.name !== 'requireChangePassword') {
    return next({ name: 'requireChangePassword', query: { require: true } })
  }

  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    // ! We updated login route name here from `auth-login` to `login` in starter-kit
    if (!isLoggedIn) return next({ name: 'login' })

    // If logged in => not authorized
    return next({ name: 'not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    return next({ name: 'profile' })
  }

  // console.log('to', { to, getUserData: userData })
  // if (to.name === 'accounts') {
  //   to.meta.breadcrumb[0] = {
  //     text: get(userData, 'username', 'Accounts'),
  //     // custom: true,
  //     to: {
  //       name: 'accounts',
  //     },
  //   }
  // }

  if ([
    'accounts',
    'reports-agent-winlose-simple',
    'reports-agent-winlose-detail',
    'test-reports-agent-winlose-simple',
    'test-reports-agent-winlose-detail',

    'reports-member-winlose-simple',
    'reports-member-winlose-detail',
    'reports-member-winlose-lists',

    'reports-player-winlose-simple',
    'reports-player-winlose-detail',
    'reports-player-winlose-lists',

    'reports-match-winlose-simple',
    'reports-match-winlose-detail',
    'reports-match-winlose-lists',
  ].some(list => list === to.name)) {
    // const username = get(userData, 'username')
    const username = '<username>'
    if (!to.meta.breadcrumb.some(list => list.text === username) && username) {
      to.meta.breadcrumb = [...to.meta.breadcrumb, {
        text: username,
        to: {
          name: to.name,
        },
      }]
    }
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
